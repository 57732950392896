<style scoped>
.svg-icon-close{
  float: right;
  font-size: xx-small;
  font-weight: 300;
  margin-top: 2px;
}
</style>

<template>
  <Modal v-model="isShow" width="1600" footer-hide :styles="{top: '40px'}">
    <Tabs v-if="svgList.length>0" v-model="defaultTab" :animated="false" @on-click="changeTabs">
          <TabPane v-for="(item, index) in svgList" :key="index" :label="stationLabel(index)" :name="'tab'+index">
            <p v-if="!isLoadHeat" class="text-center p-b-5">
              tips: 您可以用鼠标将站内地图缩放的适应的大小， 然后点击
              <i-button size="small" type="warning" @click="handleLoadHeat">加载热力图</i-button>
            </p>
            <p v-else class="text-center p-b-5">
              tips: 因为热力图像为动态计算，请先点击
              <i-button size="small" type="primary" @click="handleClearHeat">移除热力图</i-button>，然后再对地图进行操作
            </p>
            <Row>
              <i-col :span="(showButtonIcons.length>0&&isShowIcons)?22:24">
                <div :id="'heat_' + svgId + '_' + index">
                  <div v-html="item.svg" name="svgStationHtml" style="height:800px" class="svg-station-content" :id="svgId + '_' + index"></div>
                </div>
              </i-col>
              <i-col v-if="showButtonIcons.length>0&&isShowIcons" span="2" class="icon-legend">
                <h3 class="p-b-5">
                  图例<a class="svg-icon-close" @click="isShowIcons=false">隐藏</a>
                </h3>
                <div v-for="(icon,index) in showButtonIcons" :key="index">
                  <p class="m-b-5">{{icon.title.substr(0,7)}}</p>
                  <Row v-if="!!icon.mergeHtml" >
                    <i-col span="12"><svg v-html="icon.svg" style="height:20px"></svg></i-col>
                    <i-col span="12"><svg v-html="icon.mergeHtml" style="height:20px"></svg></i-col>
                  </Row>
                  <svg v-else v-html="icon.svg" style="height:20px"></svg>
                </div>
              </i-col>
            </Row>
          </TabPane>
      </Tabs>

      <div v-else class="default-content">
          <p style="font-size: xx-large;">抱歉，暂无SVG站点地图</p>
      </div>

    <Spin size="large" fix v-if="spinShow">
        <Icon type="ios-loading" size=18 class="-spin-icon-load"></Icon>
        <div>地图加载中</div>
    </Spin>
  </Modal>
</template>

<script>
import h337 from 'heatmap.js'
import svgPanZoom from 'svg-pan-zoom'
import { newGuid } from '@/utils/mapCommon'

import { getRimStationSvgByStationId } from '@/api/rim/stationgraph'
import { getIcon } from '@/api/draw/svg'

export default {
  props: {
    stationSvgModal: { type: Boolean, required: true },
    stationId: {
      type: [String, Number], required: true
    }
  },
  data () {
    return ({
      isShow: false,
      spinShow: false,
      isLoadHeat: false,

      svgList: [],
      defaultTab: 'tab0',
      svgId: '',
      stationTabLables: [], // 用于标注点位数量

      buttonIcons: [],
      showButtonIcons: [], // 需要显示的ICON清单
      isShowIcons: true,
      existSvgZoom: [],
      buttonIconsSizeLarge: [1, 2, 4, 5, 6, 68, 72, 77, 79, 80, 81, 87, 88],
      buttonIconsSizeSmall: [78],

      heatData: [], // 热力数据
      heapMapIns: null
    })
  },
  methods: {
    initPageData () {
      this.spinShow = true
      this.isShowIcons = true
      this.isLoadHeat = false
      this.heatData = [] // 清空热力数据
      this.loadIcons()
      // 获取站点svg条件字段封装
      const postData = { stationIds: this.stationId }
      getRimStationSvgByStationId(postData).then(res => {
        this.svgList = res
        this.stationTabLables = []
        let label
        for (let index = 0; index < res.length; index++) {
          label = (res[index].floor > 0) ? '地上' : '地下'
          label += res[index].floor + '层' + res[index].positionName
          this.stationTabLables.push({
            label: label,
            quantity: 0
          })
        }

        if (this.svgList.length > 0) {
          // this.defaultTab = 'tab0'
          // this.beginSvgPanZoom(this.svgId + '_0')
          this.changeTabs('tab0')
          this.$emit('svg-finished')
        } else {
          this.$emit('svg-finished')
        }
      })
    },
    beginSvgPanZoom (svgId) {
      if (this.existSvgZoom.indexOf(svgId) > -1) {
        return
      } else {
        this.existSvgZoom.push(svgId)
      }

      this.$nextTick(() => {
        const currentSvgElement = document.getElementById(svgId).firstElementChild
        // 设置svg呈现在画板上的尺寸
        currentSvgElement.setAttribute('width', '100%')
        currentSvgElement.setAttribute('height', '100%')

        var curMap = svgPanZoom(currentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        if (currentSvgElement.id === '') {
          curMap.zoom(1.5)
          this.addMapMouseEvent(currentSvgElement)
        }
      })
    },
    changeTabs (name) {
      this.defaultTab = name
      // 清空现有热力图
      this.handleClearHeat()
      const currentSvgId = this.svgId + '_' + name.substr(3)
      this.beginSvgPanZoom(currentSvgId)

      // 确认需要显示的ICON
      this.showButtonIcons = []
      this.chooseIcon = null

      this.$nextTick(() => {
        const gNodeList = document.getElementById(currentSvgId).getElementsByTagName('g')
        if (gNodeList.length > 0) {
          for (let j = 0; j < gNodeList.length; j++) {
            const newEl = gNodeList[j]

            if (newEl.hasAttribute('data-devicemodelid')) {
              // 双面电子屏, 同时不包含301的电子屏
              if (newEl.getAttribute('data-devicemodelid') === '306') {
                if (!this.showButtonIcons.find(x => x.deviceModels === '301') && this.buttonIcons.find(x => x.deviceModels === '301')) {
                  this.showButtonIcons.push(
                    this.buttonIcons.find(x => x.deviceModels === '301')
                  )
                }
              } else if (!this.showButtonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid')) &&
                  this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))) {
                this.showButtonIcons.push(
                  this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))
                )
              }
            }
          }
        }
      })
    },
    choseSpinShow () {
      this.spinShow = false
    },
    loadIcons () {
      getIcon().then(res => {
        this.buttonIcons = res.map((item) => {
          let svgHtml = item.svg
          let svgTitle = item.title
          let mergeHtml = ''
          // 设置部分图标放大比例显示
          if (this.buttonIconsSizeLarge.includes(item.id)) {
            svgHtml = svgHtml.replace('<g>', '<g transform="scale(1.5)">')
          } else if (this.buttonIconsSizeSmall.includes(item.id)) {
            svgHtml = svgHtml.replace('<g>', '<g transform="scale(0.7)">')
          }

          if (item.deviceModels === '114') {
            svgTitle = '柱贴'
            mergeHtml = res.filter(x => x.deviceModels === '114')[1].svg
          } else if (item.deviceModels === '124') {
            svgTitle = '包柱灯箱'
            mergeHtml = res.filter(x => x.deviceModels === '124')[1].svg
          }

          return {
            title: svgTitle,
            svg: svgHtml,
            mergeHtml: mergeHtml,
            deviceModels: item.deviceModels // ICON对应的媒体类型ID
          }
        })
      })
    },
    stationLabel (index) {
      return (h) => {
        return h('div', [
          h('span', this.stationTabLables[index].label),
          h('Badge', {
            style: {
              left: '5px',
              top: '-10px'
            },
            props: {
              count: this.stationTabLables[index].quantity
            }
          })
        ])
      }
    },
    addMapMouseEvent (svgElement) { // 添加普通地图的事件
      const svgChidrenElement = svgElement.lastElementChild.lastElementChild.children

      if (svgChidrenElement) {
        for (let index = 0; index < svgElement.lastElementChild.children.length; index++) {
          // 移除title标签， 改标签用于tips
          if (svgElement.lastElementChild.children[index].nodeName === 'title') {
            svgElement.lastElementChild.removeChild(svgElement.lastElementChild.children[index])
          } else if (svgElement.lastElementChild.children[index].children[0].nodeName === 'title') {
            svgElement.lastElementChild.children[index].removeChild(svgElement.lastElementChild.children[index].children[0])
          }
        }

        for (let index = 0; index < svgChidrenElement.length; index++) {
          if (svgChidrenElement[index].nodeName === 'g' && svgChidrenElement[index].hasAttribute('data-id')) {
            // 为媒体元素添加鼠标事件
            const curElement = svgChidrenElement[index]
            const xOffset = 15
            const yOffset = 15
            // curElement.setAttribute('cursor', 'pointer')

            curElement.addEventListener('mouseover', (e) => {
              for (let i = 0; i < curElement.children.length; i++) {
                if (curElement.children[i].nodeName !== 'text' && curElement.children[i].hasAttribute('stroke') && curElement.children[i].getAttribute('stroke') !== 'null') {
                  curElement.children[i].setAttribute('stroke', '#19be6b')
                }
              }

              var divTemp = document.createElement('div')
              var nodes = null
              // 文档片段，一次性append，提高性能
              var fragment = document.createDocumentFragment()
              divTemp.innerHTML = "<div id='screenshot'><p>" + curElement.getAttribute('data-devicemodel') + '</p><p>' + curElement.getAttribute('data-devicecode') + '</p></div>'
              nodes = divTemp.childNodes
              for (var i = 0, length = nodes.length; i < length; i += 1) {
                fragment.appendChild(nodes[i].cloneNode(true))
              }
              svgElement.parentNode.appendChild(fragment)
              // 据说下面这样子世界会更清净
              nodes = null
              fragment = null

              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
            curElement.addEventListener('mouseout', (e) => {
              for (let j = 0; j < curElement.children.length; j++) {
                if (curElement.children[j].nodeName !== 'text' && curElement.children[j].hasAttribute('stroke') && curElement.children[j].getAttribute('stroke') !== 'null') {
                  curElement.children[j].setAttribute('stroke', '#000000')
                }
              }

              document.getElementById('screenshot').parentNode.removeChild(document.getElementById('screenshot'))
            })
            curElement.addEventListener('mousemove', (e) => {
              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
          }
        }
      }
    },
    handleLoadHeat () {
      this.isLoadHeat = true
      this.spinShow = true
      if (this.heatData.length > 0) {
        this.initHeatMapOrigin()
      } else {
        // 需要从父窗口获取数据，然后加载热力
        this.$emit('loadHeat')
      }
    },
    handleClearHeat () {
      this.isLoadHeat = false
      if (this.heapMapIns) {
        this.heapMapIns.setData({
          max: 0,
          data: []
        })
        // 删除热力元素
        const heatmapCanvas = document.querySelector('.heatmap-canvas')
        if (heatmapCanvas) {
          heatmapCanvas.remove()
        }
      }
    },
    initHeatMapOrigin (params) {
      if (params) {
        this.heatData = params
      }

      const svgIndex = parseInt(this.defaultTab.split('tab')[1])
      const currentAssetId = this.svgList[svgIndex].assetId
      const currentFloor = this.svgList[svgIndex].floor
      // 获取当前SVG内的可用数据
      const svgMapHeatData = this.heatData.filter(x => x.assetId === currentAssetId && x.floor === currentFloor)
      // 获取当前SVG内的所有设备坐标
      const deviceCoordinateArray = this.getResourceCoordinate(svgIndex)

      if (svgMapHeatData.length > 0 && deviceCoordinateArray.length > 0) {
        this.beginLoadHeatMapOrigin(svgMapHeatData, deviceCoordinateArray, svgIndex)
      } else {
        this.spinShow = false
      }
    },
    getResourceCoordinate (svgIndex) {
      const coordinateArray = []
      const currentSvgId = this.svgId + '_' + svgIndex
      const pathNodeList = document.getElementById(currentSvgId).getElementsByTagName('path')
      const gNodeList = document.getElementById(currentSvgId).getElementsByTagName('g')

      if (pathNodeList.length > 0) {
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]
          if (el.hasAttribute('data-id')) {
            coordinateArray.push({
              deviceId: parseInt(el.getAttribute('data-id')),
              x: this.getElementCenter(el.getBoundingClientRect()).x - 180,
              y: this.getElementCenter(el.getBoundingClientRect()).y - 120
            })
          }
        }
      }

      // 新版地图使用g
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]
          if (newEl.hasAttribute('data-id')) {
            coordinateArray.push({
              deviceId: parseInt(newEl.getAttribute('data-id')),
              x: this.getElementCenter(newEl.getBoundingClientRect()).x - 180,
              y: this.getElementCenter(newEl.getBoundingClientRect()).y - 120
            })
          }
        }
      }

      return coordinateArray
    },
    beginLoadHeatMapOrigin (svgHeatdata, devices, svgIndex) {
      let deviceHeatData = null
      let heatValue = 0
      const pointsArr = []
      let maxCount = 0

      devices.forEach(element => {
        deviceHeatData = svgHeatdata.find(x => x.deviceId === element.deviceId)
        heatValue = deviceHeatData ? deviceHeatData.value : 0

        if (maxCount < heatValue) {
          maxCount = heatValue
        }

        if (heatValue > 0) {
          pointsArr.push({
            x: element.x,
            y: element.y,
            value: heatValue
          })
        }
      })

      // 开始加载数据
      this.heapMapIns = h337.create({
        container: document.getElementById('heat_' + this.svgId + '_' + svgIndex)
      })

      this.heapMapIns.setData({
        max: maxCount,
        data: pointsArr
      })

      this.spinShow = false
    },
    getElementCenter (clientRect) {
      return {
        x: parseInt(clientRect.x + (clientRect.width / 2)),
        y: parseInt(clientRect.y + (clientRect.height / 2))
      }
    }
  },
  watch: {
    isShow (val) {
      this.$emit('update:stationSvgModal', val)
    },
    stationSvgModal (val) {
      this.isShow = val
      if (val) {
        this.defaultTab = 'tab0'
        this.svgList = []
        this.svgId = newGuid()
        this.initPageData()
      }
    }
  }
}
</script>
