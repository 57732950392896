<style scoped>
.svgContent {
  width: 100%;
  text-align: center
}
</style>

<template>
  <div>
    <div v-html="svgHtml" id="svgLine_station" class="svgContent"></div>

    <!-- 显示站内地图热力 -->
    <SvgStationHeat ref="svgStationHeat" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId"
      @svg-finished="svgFinished" @loadHeat="handleLoadStationHeatData"/>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import SvgStationHeat from '@/components/svg/SvgStationHeat'

import { generateResourceImageMarker } from '@/utils/mapCommon'

import { getCitys } from '@/api/product/asset'
import { getCitySvg } from '@/api/rim/asset'
import { countSaleDataGbResource } from '@/api/dw/order'

export default {
  components: {
    SvgStationHeat
  },
  props: {
    queryModel: {
      type: Object
    }
  },
  data () {
    return {
      svgHtml: '',
      publisherId: this.$store.getters.token.userInfo.publisherId,

      mapCenterPoint: [
        { publisherId: 6, center: '5429,10707', city: '宁波' },
        { publisherId: 13, center: '267,5307', city: '青岛' },
        { publisherId: 25, center: '11339,11366', city: '佛山' }
      ],

      currentMapObj: null,
      pathNodeList: [],

      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0
    }
  },
  mounted () {
    this.initMap()
    // 设置中间区域地图高度
    this.$nextTick(() => {
      document.getElementById('svgLine_station').style.height = document.body.clientHeight - 5 + 'px'
    })
  },
  methods: {
    initMap () {
      this.$emit('map-finished', false)

      getCitys({ publisherId: this.publisherId }).then(citys => {
        const data = {
          cityCode: citys[0].cityCode,
          categoryId: null,
          publisherId: this.publisherId
        }

        getCitySvg(data).then(res => {
          this.svgHtml = res
          // 设置SVG地图的样式和缩放
          this.$nextTick(() => {
            // 设置svg呈现在画板上的尺寸
            document.getElementById('drawing').setAttribute('width', '100%')
            document.getElementById('drawing').setAttribute('height', '100%')

            this.currentMapObj = svgPanZoom('#drawing', {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              minZoom: 1,
              maxZoom: 5,
              center: true
            })

            this.currentMapObj.zoom(1.5)
            this.pathNodeList = document.getElementById('svgLine_station').getElementsByTagName('path')
            this.autoPointCenter()

            this.$emit('map-finished', true)
          })
        })
      })
    },
    autoPointCenter () {
      let pathElement
      let pathStationId
      let centerPointStr

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pathElement = this.pathNodeList[i]
        if (pathElement.hasAttribute('data-stationid') && (pathElement.getAttribute('name') === 'p_station' || pathElement.getAttribute('name') === 'p_r_station')) {
          // 获取当前元素所对应的站点ID
          pathStationId = pathElement.getAttribute('data-stationid')
          // 设置地图中心点
          if (pathStationId === this.mapCenterPoint.find(x => x.publisherId === this.publisherId).center) {
            centerPointStr = pathElement.parentNode.getAttribute('transform').substr(10).split(')')[0].split(' ')
          }
          // 为所有站点标注气泡, 用于打开站内地图
          pathElement.after(generateResourceImageMarker(this, pathStationId, pathElement.getAttribute('name'), 'orange'))
        }
      }

      const centerPoint = {
        id: pathElement.getAttribute('data-stationid'),
        x: parseInt(centerPointStr[0]),
        y: parseInt(centerPointStr[1])
      }

      // 计算出实际跳转坐标
      const svgSizes = this.currentMapObj.getSizes()
      if (centerPoint) { // 处理关闭窗口后的空值
        const newPoint = {
          x: -(centerPoint.x * svgSizes.realZoom) + (svgSizes.width / 2),
          y: -(centerPoint.y * svgSizes.realZoom) + (svgSizes.height / 2)
        }

        this.handleMapAnimation(newPoint)
      }
    },
    handleMapAnimation (targetPoint) {
      const _self = this
      const currentPan = this.currentMapObj.getPan()

      var animationTime = 300 // ms
      var animationStepTime = 30 // one frame per 30 ms
      var animationSteps = animationTime / animationStepTime
      var animationStep = 0
      var intervalID = null
      var stepX = (currentPan.x - targetPoint.x) / animationSteps
      var stepY = (currentPan.y - targetPoint.y) / animationSteps

      intervalID = setInterval(function () {
        if (animationStep++ < animationSteps) {
          _self.currentMapObj.pan({ x: currentPan.x - stepX * animationStep, y: currentPan.y - stepY * animationStep })
        } else {
          // 取消无限循环, 获取页面站点坐标系
          clearInterval(intervalID)
        }
      }, animationStepTime)
    },
    handleClickStation (stationId) {
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    handleLoadStationHeatData () {
      const postData = {
        endDate: this.queryModel.endDate,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: this.queryModel.startDate,
        type: this.formatQueryType(this.queryModel.heatType),
        stationIds: this.chooseStationId
      }

      countSaleDataGbResource(postData).then(res => {
        this.$refs.svgStationHeat.initHeatMapOrigin(res)
      })
    },
    svgFinished () {
      // SVG站点地图加载完毕, 计算热力数据，并加载
      this.$refs.svgStationHeat.choseSpinShow()
    },
    clearMap () {
      this.currentMapObj = null
    },
    formatQueryType (heatType) {
      // 数据类型(1:投放量,2:去化率,3:预售率;4:品牌;5:执行额;6:签约额)
      let searchType = 0
      switch (heatType) {
        case '0':
          searchType = 1
          break
        case '1':
          searchType = 2
          break
        case '2':
          searchType = 3
          break
        case '3':
          searchType = 4
          break
        case '4':
          searchType = 6
          break
        case '5':
          searchType = 5
          break
        default:
          break
      }

      return searchType
    }
  }
}
</script>
